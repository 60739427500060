import React, { createContext, useState, useEffect } from 'react';

const RaceResultParticipants = createContext();

const RaceResultParticipantsProvider = ({ children }) => {
  const [counter, setCounter] = useState(
    (typeof window !== 'undefined' && localStorage.getItem('participantCounter')) || 11000
  );

  useEffect(() => {
    const fetchUser = () => {
      fetch(`https://api.lmg.to/participants-counter/`)
        .then((response) => response.json())
        .then((resultData) => {
          setCounter(resultData.counter);
          localStorage.setItem('participantCounter', resultData.counter);
        });
    };

    fetchUser();

    const intervalCall = setInterval(() => {
      fetchUser();
    }, 60000);

    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  return <RaceResultParticipants.Provider value={counter}>{children}</RaceResultParticipants.Provider>;
};

export { RaceResultParticipants, RaceResultParticipantsProvider };
