import SimpleReactLightbox from 'simple-react-lightbox';

import React, { useState, useEffect } from 'react';

import { RaceResultParticipants, RaceResultParticipantsProvider } from './src/provider/RaceResultParticipants';
import './src/styles/global.scss';

export const onClientEntry = () => {
  window.gatsbyClientEntry = true;
  window.gatsbyOnClientEntry && window.gatsbyOnClientEntry();
};

export const onInitialClientRender = () => {
  window.gatsbyInitialClientRender = true;
  window.gatsbyOnInitialClientRender && window.gatsbyOnInitialClientRender();
};

export const wrapPageElement = ({ element, props }) => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  if (!hydrated) {
    return element;
  }
  return element;

  return (
    <SimpleReactLightbox>
      <RaceResultParticipantsProvider>{element}</RaceResultParticipantsProvider>
    </SimpleReactLightbox>
  );
};
